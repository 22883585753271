import React from 'react'

function SectionFaq() {
  return (
    <div className="bg-[#FDB813] pt-[30px] pb-[30px] lg:pt-[170px] lg:pb-[110px] flex items-center justify-center flex-col noise">
        <p className='text-[16px] lg:text-[30px] font-semibold'>Frequently Asked Questions</p>
      <h1 className="text-[90px] lg:text-[250px] text-[#0a0a0a] font-bold">FAQ</h1>
    </div>
  )
}

export default SectionFaq