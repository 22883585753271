import React from "react";
import { Link } from "react-router-dom";

function MobileMenu({activeToggle}) {
  const currentYear = new Date().getFullYear();
  return (
    <div className="relative z-10 w-[100%] h-[100vh] bg-[#FDB813] flex flex-col items-center justify-start noise">
      <div className="flex flex-col items-center justify-between"></div>
      <div className="flex flex-col no-underline no-list-style gap-[30px] items-center justify-center mt-[50px]">
        <li>
          <Link to="/" className="text-[32px] font-bold" onClick={activeToggle}>Home</Link>
        </li>
        <li>
          <Link to="/about-us" className="text-[32px] font-bold" onClick={activeToggle}>About</Link>
        </li>
        <li>
          <Link to="/contact-us" className="text-[32px] font-bold" onClick={activeToggle}>Contact</Link>
        </li>
        <li>
          <Link to="/faq" className="text-[32px] font-bold" onClick={activeToggle}>FAQ</Link>
        </li>
      </div>
      <div className="flex gap-[20px] mt-[60px]">
          <a href='https://play.google.com/store/apps/details?id=com.fintech.monzonecredit' target="blank" className="bg-white w-[160px] h-[50px] rounded-[30px] flex items-center justify-center gap-[7px]">
            <img src="/assets/apple_logo.png" alt="" />
            <p className="mb-0">Google Play</p>
          </a>
          <a  href='https://apps.apple.com/ng/app/monzone/id6502399515' target="blank" className="bg-white w-[160px] h-[50px] rounded-[30px] flex items-center justify-center gap-[7px]">
            <img src="/assets/apple_logo.png" alt="" />
            <p className="mb-0">Apple</p>
          </a>
        </div>
        <p className="absolute bottom-5 text-[24px]">&copy; Monzone {currentYear}</p>
    </div>
  );
}

export default MobileMenu;
