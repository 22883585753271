import React, { useEffect, useState } from "react";

const DownloadScreen = () => {
  const [device, setDevice] = useState(null);

  // Function to determine the device type
  const deviceCheck = () => {
    if (/Android/i.test(navigator.userAgent)) {
      setDevice("android");
    } else if (/iPhone|iPad|Macintosh|iPod/i.test(navigator.userAgent)) {
      setDevice("ios");
    } else {
      setDevice(null);
    }
  };

  useEffect(() => {
    deviceCheck();

    // Redirect based on the detected device
    if (device === "android") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.fintech.monzonecredit";
    } else if (device === "ios") {
      window.location.href =
        "https://apps.apple.com/ng/app/monzone/id6502399515";
    }
  }, [device]);

  return;
};

export default DownloadScreen;
