import React from 'react'

function SectionFour() {
  return (
    <div className='bg-white w-[100%] py-[100px]'>
        <img src="/assets/monzcred.png" alt="" className='w-[100%] hidden lg:block' />
        <img src="/assets/sect4.png" alt="" className='w-[100%] block lg:hidden' />
    </div>
  )
}

export default SectionFour