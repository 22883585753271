import React from 'react'
import SectionSeven from '../Homepage/SectionSeven'
import SectionFaq from './SectionFaq'
import FaqComponent from './FaqComponent'

function Faq() {
  return (
    <div>
        <SectionFaq/>
        <FaqComponent/>
        <SectionSeven/>
    </div>
  )
}

export default Faq