import React from "react";

function SectionContact() {
  return (
    <div className="bg-[#FDB813] pt-[80px] lg:pt-[170px] pb-[110px] flex items-center justify-center noise">
      <h1 className="text-[50px] lg:text-[250px] text-[#0a0a0a] font-bold text-center">CONTACT US</h1>
    </div>
  );
}

export default SectionContact;
