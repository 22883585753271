import React, { useState } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";

function Navbar() {
  const [activeMobile, setActiveMobile] = useState(false);

  const activeToggle = () => {
    setActiveMobile(!activeMobile);
  };

  console.log(activeMobile);
  return (
    <>
      <div className="w-[100%] h-[10vh] bg-[#FDB813] flex items-center justify-between px-[24px] lg:px-[122px] py-[31px] relative noise">
        <div className="flex items-center justify-between gap-[70px]">
          <img
            src="/assets/logo.png"
            alt=""
            className="w-[150px] h-[43px] lg:w-[190px] lg:h-[53px]"
          />
          <div className="lg:flex no-underline no-list-style gap-[30px] hidden">
            <li>
              <Link to="/" >Home</Link>
            </li>
            <li>
              <Link to="/about-us">About</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact</Link>
            </li>
            <li>
              <Link to="/faq">FAQ</Link>
            </li>
          </div>
        </div>
        <div className="lg:flex gap-[20px] hidden">
          <a href='https://play.google.com/store/apps/details?id=com.fintech.monzonecredit' target="blank" className="bg-white w-[160px] h-[50px] rounded-[30px] flex items-center justify-center gap-[7px]">
            <img src="/assets/google_play_fill.png" alt="" />
            <p className="mb-0">Google Play</p>
          </a>
          <a href='https://apps.apple.com/ng/app/monzone/id6502399515' target="blank" className="bg-white w-[160px] h-[50px] rounded-[30px] flex items-center justify-center gap-[7px]">
            <img src="/assets/apple_logo.png" alt="" />
            <p className="mb-0">Apple</p>
          </a>
        </div>
        {activeMobile ? (
          <img src="/assets/close.png" alt="" onClick={activeToggle} />
        ) : (
          <div
            className="bg-white w-[90px] h-[30px] rounded-[30px] flex items-center justify-center gap-[7px] lg:hidden"
            onClick={activeToggle}
          >
            <p className="mb-0 text-[16px] font-bold">Menu</p>
          </div>
        )}
      </div>
      {activeMobile && (
        <div className="absolute w-[100%]">
          <MobileMenu activeToggle={activeToggle}/>
        </div>
      )}
    </>
  );
}

export default Navbar;
