import React from 'react'

function SectionAbout() {
  return (
    <div className='bg-[#FDB813] pt-[70px] pb-[30px] lg:pt-[170px] lg:pb-[110px] flex items-center justify-center noise'>
        <h1 className='text-[90px] lg:text-[250px] text-[#0a0a0a] font-bold'>ABOUT</h1>
    </div>
  )
}

export default SectionAbout